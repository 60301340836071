import React from 'react'

import { Layout, Navbar } from '../Layout'
import { TermsOfService } from '../components/TermsOfService'

const TOSPage = () => (
	<Layout
		title="Terms of Service"
		navbar={<Navbar position="absolute"/>}
		content={<TermsOfService/>}
		footer={null}
	/>
)

export default TOSPage